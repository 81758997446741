import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

window.addEventListener('turbolinks:load', () => {
//document.addEventListener('DOMContentLoaded', () => {
    $('.select2').select2({
        // tags: true,
        // tokenSeparators: [',', ' '],
    });
})
