$(document).on('turbolinks:load', function() {
  $('.best_in_place').best_in_place();
})

window.sortTable = function(n, table) {
  var rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = $(table).closest('table')[0]
  switching = true;
  // Set the sorting direction to ascending:
  dir = "asc";
  /* Make a loop that will continue until
  no switching has been done: */
  while (switching) {
    // Start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /* Loop through all table rows (except the
    first, which contains table headers): */
    for (i = 1; i < (rows.length - 1); i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Get the two elements you want to compare,
      one from current row and one from the next: */
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /* Check if the two rows should switch place,
      based on the direction, asc or desc: */
      if (dir == "asc") {
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      } else if (dir == "desc") {
        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark that a switch has been done: */
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      // Each time a switch is done, increase this count by 1:
      switchcount ++;
    } else {
      /* If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again. */
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}

window.changePosition = function(n) {
  fields = $('.nested-fields')
  if (n == 'SR' || n == 'TE') {
    $(fields[1]).find('*[id*=position]').val(n)
    $(fields[2]).find('*[id*=position]').val(n)
  } else if (n == 'TE left') {
    $(fields[1]).find('*[id*=position]').val('TE')
    $(fields[2]).find('*[id*=position]').val('SR')
  } else if (n == 'TE right') {
    $(fields[1]).find('*[id*=position]').val('SR')
    $(fields[2]).find('*[id*=position]').val('TE')
  }
}

window.changeRoute = function(n) {
  fields = $('.nested-fields')
  if (n == 'knight') {
    $(fields[0]).find('*[id$=route]').val('slant')
    $(fields[1]).find('*[id$=route]').val('arrow')
    $(fields[2]).find('*[id$=route]').val('arrow')
    $(fields[3]).find('*[id$=route]').val('slant')
  } else if (n == 'ninja') {
    $(fields[0]).find('*[id$=route]').val('fade')
    $(fields[1]).find('*[id$=route]').val('5out')
    $(fields[2]).find('*[id$=route]').val('5out')
    $(fields[3]).find('*[id$=route]').val('fade')
  } else if (n == 'shoulin') {
    $(fields[0]).find('*[id$=route]').val('slant')
    $(fields[1]).find('*[id$=route]').val('seam')
    $(fields[2]).find('*[id$=route]').val('seam')
    $(fields[3]).find('*[id$=route]').val('slant')
  } else if (n == 'apache') {
    $(fields[0]).find('*[id$=route]').val('hitch')
    $(fields[1]).find('*[id$=route]').val('hitch')
    $(fields[2]).find('*[id$=route]').val('hitch')
    $(fields[3]).find('*[id$=route]').val('hitch')
  } else if (n == 'washington') {
    $(fields[0]).find('*[id$=route]').val('fade')
    $(fields[1]).find('*[id$=route]').val('seam')
    $(fields[2]).find('*[id$=route]').val('seam')
    $(fields[3]).find('*[id$=route]').val('fade')
  }
}
