// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "chartkick/chart.js"
// import 'best_in_place/best_in_place.js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap'

// require('jquery')
window.jQuery = window.$ = require('jquery')
require("./best_in_place")

require('./nested-forms/addFields')
require('./nested-forms/removeFields')

require('./global')

require('./select2setup')
